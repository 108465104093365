import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { BrMaskerModule } from 'br-mask';
// Components
import { AccordionComponent } from './accordion/accordion.component';
import { ChipsComponent } from './chips/chips.component';
import { DesktopMenuComponent } from './desktop-menu/desktop-menu.component';
import { FooterComponent } from './footer/footer.component';
import { MailingComponent } from './mailing/mailing.component';
import { ModalLoginComponent } from './mailing/modal-login/modal-login.component';
import { ModalRegisterComponent } from './mailing/modal-register/modal-register.component';
import { ManagerComponent } from './manager/manager.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { NgxJsonLdComponent } from './ngx-json-ld/ngx-json-ld.component';
import { ProductsComponent } from './products/products.component';
import { SearchComponent } from './search/search.component';
import { TabsComponent } from './tabs/tabs.component';
import { ModalForgotPasswordComponent } from './mailing/modal-forgot-password/modal-forgot-password.component';
import { ModalDeleteComponent } from './mailing/modal-delete/modal-delete.component';
import { ModalUnsubscribeComponent } from './mailing/modal-unsubscribe/modal-unsubscribe.component';
import { ModalEditComponent } from './mailing/modal-edit/modal-edit.component';

@NgModule({
  declarations: [
    AccordionComponent,
    FooterComponent,
    SearchComponent,
    TabsComponent,
    ManagerComponent,
    ProductsComponent,
    ChipsComponent,
    NgxJsonLdComponent,
    DesktopMenuComponent,
    MobileMenuComponent,
    MailingComponent,
    ModalLoginComponent,
    ModalRegisterComponent,
    ModalForgotPasswordComponent,
    ModalDeleteComponent,
    ModalUnsubscribeComponent,
    ModalEditComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule,
    BrMaskerModule,
  ],
  exports: [
    AccordionComponent,
    FooterComponent,
    SearchComponent,
    TabsComponent,
    ManagerComponent,
    ProductsComponent,
    ChipsComponent,
    NgxJsonLdComponent,
    DesktopMenuComponent,
    MobileMenuComponent,
    MailingComponent,
    ModalLoginComponent,
    ModalRegisterComponent,
    ModalForgotPasswordComponent,
    ModalDeleteComponent,
    ModalUnsubscribeComponent,
    ModalEditComponent,
  ],
})
export class ShareModule {}
