import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateService {

  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private toastController: ToastController
  ) {
    if (environment.production) {
      this.startingUpdateCheck();
    }
  }

  private startingUpdateCheck(): void {
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));

    const everySixHours$ = interval(60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate());
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe(() => this.promptUser());
  }

  private promptUser(): void {
    this.updates.activateUpdate().then(() => this.showToast());
  }

  private async showToast(): Promise<void> {
    const toast = await this.toastController.create({
      message: 'Uma nova versão está disponível!',
      cssClass: 'custom-toast',
      mode: 'md',
      duration: 1500,
    });
    toast.present();
    toast.onDidDismiss().then(() => window.location.reload());
  }
}
