import { Injectable } from '@angular/core';

// Models
import { DataStorage } from '@models/data-storage';
@Injectable({
  providedIn: 'root'
})
export class StorageService {


  storage = {
    akin: {
      index: 0,
      questions: []
    },
    arctium: {
      index: 0,
      questions: []
    },
    daikon: {
      index: 0,
      questions: []
    },
    lotus: {
      index: 0,
      questions: []
    },
    meyenii: {
      index: 0,
      questions: []
    },
    terrax: {
      index: 0,
      questions: []
    },
  }

  constructor() { }

  getProduct(product: string): DataStorage {
    return this.storage[product];
  }

  setProduct(product: string, data: any, index: number): void {
    this.storage[product].index = index;
    this.storage[product].questions = [...data];
  }

  setIndexProduct(product: string, index: number): void {
    this.storage[product].index = index;
  }

}
