import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UserModel } from '@models/user.model';
import firebase from "firebase/compat/app";
import "firebase/auth";

@Injectable({
  providedIn: 'root'
})
export class RefreshUserService {

  user: UserModel;

  constructor(private fireAuth: AngularFireAuth) { }

  readonly base_url = '../database/data.json';

  async reauthenticateWithCredential(uid: string): Promise<void> {
    const isValid = uid === this.user.uid ? true : false;
    if (isValid) {
      this.fireAuth.user.subscribe(async (user: firebase.User) => {
        const credential = firebase.auth.EmailAuthProvider.credential(this.user.email, this.user.refresh_token);
        await user.reauthenticateWithCredential(credential)
      });
    }
  }
}
