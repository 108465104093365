// Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  showTab = 'manager';
  url: string;
  product: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.checkingTab();
  }

  splitUrl(): void {
    this.url = this.router.url.split('/', 4)[3];
  }

  checkingTab(): void {
    this.splitUrl();
    if (this.url) {
      this.showTab = this.url;
    } else {
      this.navigate('manager');
    }
  }

  segmentChanged(ev: CustomEvent) {
    this.navigate(ev.detail.value);
  }

  receiveEventEmitter(name: string): void {
    this.product = name;
  }

  navigate(name: string): void {

    if (name === 'products' && this.product) {
      const url = `/home/faq/${name}/${this.product.toLowerCase()}`;
      this.router.navigate([url]);
    }
    else {
      const url = `/home/faq/${name}`;
      this.router.navigate([url]);
    }
  }
}
