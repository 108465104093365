import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { LoggedInUser, MailingRequest, SalesforceResponse } from '@models/salesforce-data';
import { Params } from '@models/user-verification';
import { RefreshUserService } from '@services/refresh-user.service';
import { SalesforceService } from '@services/salesforce.service';
import { ScreenSizeService } from '@services/screen-size.service';
import { Observable } from 'rxjs';
import { ModalDeleteComponent } from '../mailing/modal-delete/modal-delete.component';
import { ModalEditComponent } from '../mailing/modal-edit/modal-edit.component';
import { ModalLoginComponent } from '../mailing/modal-login/modal-login.component';
import { ModalRegisterComponent } from '../mailing/modal-register/modal-register.component';
import { ModalUnsubscribeComponent } from '../mailing/modal-unsubscribe/modal-unsubscribe.component';
import { ToastMessageService } from '@services/toast-message.service';
import { ModalResponse } from '@models/modal';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
@Component({
  selector: 'app-mailing',
  templateUrl: './mailing.component.html',
  styleUrls: ['./mailing.component.scss'],
})
export class MailingComponent implements OnInit {
  isDesktop$: Observable<boolean> = this.screenSizeService.isDesktopView();
  mailing: MailingRequest;
  user: LoggedInUser;
  groupMailing = [];
  name: string = '';
  isDesktop: boolean;
  isAllMailingSelected: boolean;
  isAuthorization: boolean = false;
  isAllGroups: boolean = true;
  isItemGroup: boolean = true;
  isActive: boolean = true;
  loading: boolean = false;

  constructor(
    private screenSizeService: ScreenSizeService,
    public modalController: ModalController,
    private router: Router,
    private salesforceService: SalesforceService,
    private toastMessageService: ToastMessageService,
    public toastController: ToastController,
    public refresh: RefreshUserService,
    private activatedRoute: ActivatedRoute,
    private analytics: AngularFireAnalytics,
  ) { }

  ngOnInit(): void {
    this.checkingTab();
    this.checkUserIsLoggedIn();
    this.isDesktopSubscriber();
    this.getQueryParams();
  }

  getMailingListItems(): void {
    this.loading = true;
    this.salesforceService.getMailing().subscribe({
      next: (mailing: SalesforceResponse) => {
        this.groupMailing = mailing.data.todosMailings.gruposMailings;
        this.loading = false;
      },
      error: (err) => console.log(err)
    });
  }

  selectAll(event: boolean): void {
    this.isAllMailingSelected = event;
    if (this.isAllGroups) {
      this.groupMailing.map((item: any) => {
        item.inscritoGrupo = event;
        item.mailings.map((fund: any) => {
          fund.inscritoMailing = event;
        });
      });
    }
    this.isAllGroups = true;
  }

  selectAllItems(event: boolean, index: number): void {
    this.isActive = false;
    this.groupMailing[index].inscritoGrupo = event;
    if (this.isItemGroup) {
      this.groupMailing[index].mailings.filter((item: any) => {
        item.inscritoMailing = event;
      });
    }

    const isInscritoGrupo = (item: any) => item.inscritoGrupo === true;
    const result = this.groupMailing.every(isInscritoGrupo);

    setTimeout(() => {
      if (!result && this.isAllMailingSelected && !event) {
        this.isAllGroups = false;
        this.isAllMailingSelected = event;
      } else if (result && !this.isAllMailingSelected && event) {
        this.isAllGroups = false;
        this.isAllMailingSelected = event;
      }
      this.isItemGroup = true;
    });
  }

  selectItem(event: boolean, i: number, id: string): void {
    this.isActive = false;
    this.groupMailing[i].mailings.map((item: any) => {
      if (item.idMailing === id) {
        item.inscritoMailing = event;
      }
      return item;
    });

    const isInscritoMailing = (item: any) => item.inscritoMailing === true;
    const result = this.groupMailing[i].mailings.every(isInscritoMailing);

    if (!result && this.groupMailing[i].inscritoGrupo && !event) {
      this.isItemGroup = false;
      this.groupMailing[i].inscritoGrupo = event;
    } else if (result && !this.groupMailing[i].inscritoGrupo && event) {
      this.isItemGroup = false;
      this.groupMailing[i].inscritoGrupo = event;
    }
  }

  updateMailing(): void {
    this.loading = true;
    this.sendAnalytics('mailing_update');
    this.setNewMailingConfiguration();
    this.salesforceUpdateMailing(this.user);
  }

  setNewMailingConfiguration(): void {
    this.mailing = {
      todosMailings: {
        inscritoTodos: this.isAllMailingSelected,
        gruposMailings: this.groupMailing,
      },
    };
  }

  salesforceUpdateMailing(user: LoggedInUser): void {
    this.salesforceService.update(this.mailing)
      .subscribe({
        next: (response: SalesforceResponse) => {
          user.groupMailing = response.data.todosMailings.gruposMailings;
          user.all = response.data.todosMailings.inscritoTodos;
          this.setUser(user);
          this.loading = false;
          this.isActive = true;
          response.data.status
            ? this.toastMessageService.message('Mailing atualizado com sucesso!')
            : this.toastMessageService.message('Erro ao Atualizar o Mailing');
        },
        error: (err) => console.log(err)
      });
  }

  async modal(id: string): Promise<void> {
    const { component, cssClass, componentProps } =
      this.handleComponentSelect(id);
    const modal = await this.modalController.create({
      component: component,
      cssClass: cssClass,
      mode: 'md',
      componentProps: componentProps,
    });

    return await modal.present().then(async () => {
      await modal.onWillDismiss();
      this.isActive = true;
      this.handleLoggedUser();
    });
  }

  checkUserIsLoggedIn(): void {
    this.getUser();
    if (!this.user) {
      this.isAuthorization = false;
      this.getMailingListItems();
    } else {
      this.handleLoggedUser();
    }
  }

  getUser(): void {
    this.user = JSON.parse(sessionStorage.getItem('user'));
  }

  setUser(user: LoggedInUser): void {
    sessionStorage.setItem('user', JSON.stringify(user));
  }

  handleLoggedUser(): void {
    this.getUser();
    if (this.user) {
      this.isAuthorization = this.user.authorization;
      this.name = this.user.displayName;
      this.groupMailing = this.user.groupMailing;
      this.isAllMailingSelected = this.user.all;
    }
  }

  getQueryParams(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (Object.values(params).length !== 0) {
        this.updateVerifiedEmail(params);
      }
    });
  }

  updateVerifiedEmail(params: Params): void {
    if (params.code) {
      const data = {
        idPersona: params.code,
        emailVerificado: true,
        tipo: 'update',
      };

      this.salesforceService.create(data).subscribe(
        (response: SalesforceResponse) => {
          if (response.data.status) this.modal(params.type);
        },
        (err: any) => console.log(err)
      );

    } else {
      this.modal(params.type);
    }
  }

  async logout(): Promise<void> {
    const toast = await this.toastController.create({
      message: 'Você está sendo desconectado.',
      mode: 'md',
      duration: 2000,
    });
    toast.present();
    await toast.onDidDismiss().then(() => this.clean());
  }

  clean(): void {
    sessionStorage.removeItem('user');
    this.isAuthorization = false;
    this.isAllMailingSelected = false;
    this.getMailingListItems();
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  sendAnalytics(idName: string): void {
    this.analytics.logEvent(idName);
  }

  handleComponentSelect(modalId: string): ModalResponse {
    let response: ModalResponse;
    switch (modalId) {
      case 'register':
        this.sendAnalytics('mailing_button_register');
        response = {
          component: ModalRegisterComponent,
          cssClass: 'modal-register-custom',
        };
        break;

      case 'login':
        this.sendAnalytics('mailing_button_login');
        response = {
          component: ModalLoginComponent,
          cssClass: 'modal-login-custom',
        };
        break;

      case 'delete':
        this.sendAnalytics('mailing_button_delete');
        response = {
          component: ModalDeleteComponent,
          cssClass: 'modal-delete-custom',
        };
        break;

      case 'unsubscribe':
        this.sendAnalytics('mailing_button_unsubscribe');
        response = {
          component: ModalUnsubscribeComponent,
          cssClass: 'modal-unsubscribe-custom',
          componentProps: {
            data: {
              gruposMailings: this.groupMailing,
              inscritoTodos: this.isAllMailingSelected,
            },
          },
        };
        break;

      case 'edit':
        this.sendAnalytics('mailing_button_edit')
        response = {
          component: ModalEditComponent,
          cssClass: 'modal-register-custom',
        };
        break;
    }
    return response;
  }

  checkingTab(): void {
    switch (this.router.url) {
      case '/home':
        this.router.navigate(['/home/mailing']);
        break;

      case '/home/faq':
        this.router.navigate([this.router.url]);
        break;

      case '/home/faq/manager':
        this.router.navigate(['/home/faq']);
        break;
    }
  }

  isDesktopSubscriber(): void {
    this.isDesktop$.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });
  }
}
