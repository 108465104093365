import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment'
@Component({
  selector: 'app-desktop-menu',
  templateUrl: './desktop-menu.component.html',
  styleUrls: ['./desktop-menu.component.scss'],
})
export class DesktopMenuComponent implements OnInit {
  version = environment.version;
  constructor() { }

  ngOnInit() { }

}
