import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { Email } from '@models/create-user';
import { SalesforceResponse, LoggedInUser } from '@models/salesforce-data';
import { UserModel } from '@models/user.model';
import { AuthenticationService } from '@services/authentication.service';
import { ErrorService } from '@services/error.service';
import { RefreshUserService } from '@services/refresh-user.service';
import { SalesforceService } from '@services/salesforce.service';
import { ToastMessageService } from '@services/toast-message.service';
import firebase from 'firebase/compat/app';
import { ModalForgotPasswordComponent } from '../modal-forgot-password/modal-forgot-password.component';
import { LoginFormControl } from '@models/login';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss'],
})
export class ModalLoginComponent implements OnInit {
  form: FormGroup;
  type = 'password';
  icon = 'eye-off-outline';
  emailPattern =
    // tslint:disable-next-line: max-line-length
    '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';
  loading: boolean = false;
  error: boolean;
  message: string;

  constructor(
    public modalController: ModalController,
    private authService: AuthenticationService,
    public alertController: AlertController,
    private salesforceService: SalesforceService,
    private errorService: ErrorService,
    private refreshService: RefreshUserService,
    private toastMessage: ToastMessageService,
    private analytics: AngularFireAnalytics,
  ) { }

  // Convenience getter for easy access to form fields
  get field(): FormGroup['controls'] {
    return this.form.controls;
  }

  ngOnInit(): void { this.initForm(); }

  onSubmit(): void {
    this.loading = true;
    this.authService
      .signIn(this.form.value.email, this.form.value.password)
      .then((data: firebase.auth.UserCredential) => {
        if (data) {
          this.sendAnalytics('mailing_user_login');
          const email: Email = { email: data.user.email };
          this.getUserSalesforce(email, data.user.uid)
        }
      })
      .catch((error: any) => {
        this.errorService.error(error.code).then((response) => {
          this.loading = false;
          this.toastMessage.messageConfirm(response.message);
        });
      });
  }

  initForm(): void {
    this.form = new FormGroup<LoginFormControl>({
      email: new FormControl<string>('', Validators.compose([
        Validators.maxLength(256),
        Validators.email,
        Validators.pattern(this.emailPattern),
        Validators.required,
      ])),
      password: new FormControl<string>('', Validators.compose([Validators.required, Validators.minLength(6)])),
    })
  }

  private setUserModel(uid: string) {
    this.refreshService.user = new UserModel();
    this.refreshService.user.uid = uid;
    this.refreshService.user.email = this.form.value.email;
    this.refreshService.user.refresh_token = this.form.value.password;
  }

  async setUserStorage(response: SalesforceResponse, uid: string): Promise<void> {
    const user: LoggedInUser = {
      uid: uid,
      id: response.data.idPersona,
      displayName: response.data.primeiroNomePersona,
      name: response.data.nomePersona,
      email: response.data.emailPersona,
      authorization: response.data.status,
      groupMailing: response.data.todosMailings.gruposMailings,
      all: response.data.todosMailings.inscritoTodos,
    }
    this.setUser(user);
  }

  getUserSalesforce(email: Email, uid: string): void {
    this.salesforceService.find(email)
      .subscribe((response: SalesforceResponse) => {
        if (response.data.status) {
          this.loading = false;
          this.setUserModel(uid);
          this.setUserStorage(response, uid)
            .then(() => this.dismiss());
        }
        else {
          if (!response.data.emailVerificado) {
            this.loading = false;
            this.toastMessage.messageConfirm(response.data.msgErro)
          }
          else {
            this.loading = false;
            this.toastMessage.messageConfirm(response.data.msgErro)
          }
        }
      });
  }

  sendAnalytics(idName: string): void {
    this.analytics.logEvent(idName);
  }

  setUser(user: LoggedInUser): void {
    sessionStorage.setItem('user', JSON.stringify(user));
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async forgotpassword(): Promise<void> {
    this.sendAnalytics('mailing_forgot_password');
    const modal = await this.modalController.create({
      component: ModalForgotPasswordComponent,
      cssClass: 'modal-login-custom',
      swipeToClose: true,
      presentingElement: await this.modalController.getTop(),
    });
    return await modal.present();
  }

  openLock(): void {
    switch (this.type) {
      case 'password':
        this.type = 'text';
        this.icon = 'eye-outline';
        break;
      case 'text':
        this.type = 'password';
        this.icon = 'eye-off-outline';
        break;
    }
  }
}
