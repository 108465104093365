import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  version: string = environment.version;
  constructor() { }

  ngOnInit() { }

  openExternal(url: string): void {
    window.open(url, '_blank', 'noopener noreferrer');
  }

}
