// Angular
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

// Model
import { DataStorage } from '@models/data-storage';
import { JsonLD } from '@models/jsonLD';
import { Products } from '@models/products';
import { Question } from '@models/question';
import { Schema } from '@models/schema';
import { Topic } from '@models/topic';

// Service
import { ProductService } from '@services/product.service';
import { SeoService } from '@services/seo.service';
import { ShowFooterService } from '@services/show-footer.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  @Input() productName: string;
  @Output() productSelected = new EventEmitter();

  product: string;
  selected: string[];
  private readonly offset: number = 15;
  private index: number = 0;
  productUrl: string;
  topics: Topic[];
  questions: Question[] = [];
  questionsStorageData: DataStorage;
  lastQuestionVisible: Question[] = [];
  visibleQuestions: Question[] = [];
  selectedChips: Question[] = [];
  storageProduct: Question[] = [];
  length: number;
  keys = Object.keys;
  products = Products;
  schemaProduct: Question[];
  schema: Schema[];
  jsonLD = JsonLD;

  constructor(
    private productService: ProductService,
    private router: Router,
    private seo: SeoService,
    private showFooter: ShowFooterService,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.showFooter.activeFooter(true)
    this.splitUrl();
    this.checkingProductSelected(this.productName);
  }

  updateSEO(event: string): void {
    this.seo.generateTags({
      title: 'Relações com Investidores - Riza Asset Management | ' + event,
    });
  }

  selectProduct(event: string): void {
    if (event !== this.product) {
      this.selectedChips.length = 0;
      this.urlFormat(event);
      this.sendProductSelected(event);
      this.setProduct(event);
      this.updateSEO(event);
      this.disableFooter();
      this.checksSessionStorage();
    } else {
      this.activateSelectedProduct(event);
    }
  }

  sendProductSelected(name: string): void {
    this.productSelected.emit(name);
  }

  setProduct(event: string): void {
    this.product = event.toLowerCase();
  }

  checksSessionStorage(): void {
    this.getData(this.product);
    if (this.questionsStorageData.index > 0) {
      const questionsSlice = this.getDataStorage(this.questionsStorageData);
      this.visibleQuestions = [...questionsSlice];
      this.length = this.visibleQuestions.length;
      this.reloadData(this.product);
    } else {
      this.checksProductDataStorage(this.product);
    }
  }

  checksProductDataStorage(product: string): void {
    const data = this.getQuestionsData(product);
    if (data.questions.length > 0) {
      this.questions = data.questions;
      this.length = data.questions.length;
      this.createArrayTopic(this.questions);
      this.index = 0;
      this.handleItem(this.questions, data.index);
    } else {
      this.getQuestions(this.product);
    }
  }

  getQuestions(product: string): void {
    this.productService
      .getQuestionsAboutProduct(product)
      .subscribe((querySnapshot) => {
        this.questions = JSON.parse(querySnapshot.data().data);
        this.schemaProduct = this.questions;
        this.length = this.questions.length;
        this.createArrayTopic(this.questions);
        this.index = 0;
        this.storageService.setProduct(product, this.questions, this.index);
        this.handleItem(this.questions, this.index);
        this.initializeSchema();
      });
  }

  handleItem(data: Question[], index: number): void {
    this.visibleQuestions = data.slice(index, this.offset + this.index);
    this.index += this.offset;
  }

  reloadData(product: string): void {
    const data = this.getQuestionsData(product);
    this.questions = data.questions;
    this.createArrayTopic(this.questions);
  }

  getData(product: string): void {
    this.questionsStorageData = this.getQuestionsData(product);
  }

  getDataStorage(question: DataStorage): Question[] {
    return question.questions.slice(0, question.index);
  }

  getQuestionsData(product: string): DataStorage {
    return this.storageService.getProduct(product);
  }

  createNewArray(index: number): void {
    const news = this.questions.slice(index, this.offset + this.index);
    this.index += this.offset;
    this.visibleQuestions = [...this.visibleQuestions, ...news];
    this.storageService.setIndexProduct(this.product, this.index);
  }

  receiveEventEmitter(index: number): void {
    if (this.lastQuestionVisible.length > 0) {
      this.createNewQuestions(index);
    } else {
      this.createNewArray(index);
    }
  }

  disableFooter(): void {
    this.showFooter.activeFooter(false);
  }

  // Check if any product has already been selected
  checkingProductSelected(productName: string): void {
    if (productName || this.productUrl) {
      this.product = productName || this.productUrl;
      this.selectProduct(this.product);
    }
  }

  activateSelectedProduct(event: string): void {
    this.sendProductSelected(event);
    this.setProduct(event);
    this.disableFooter();
    this.checksSessionStorage();
  }

  splitUrl(): void {
    if (this.router.url !== '/home' && this.router.url !== '/home/products') {
      const url = this.router.url.split('/', 4)[3];
      this.productUrl = url[0].toUpperCase() + url.substr(1);
    }
  }

  urlFormat(product: string): void {
    const split = this.router.url.split('/');
    const url = `${split[1]}/${split[2]}/${product.toLowerCase()}`;
    this.router.navigate([url]);
  }

  createArrayTopic(data: Question[]): void {
    const uniqueTopics = new Set(
      data.map((item: Question) => {
        return item.topic;
      })
    );
    this.topics = [...uniqueTopics].map(
      (item: string, index: number): Topic => {
        return { id: index + 1, topic: item, active: false };
      }
    );
  }

  // Topics Chips
  receiveSelectedChips(data: any): void {
    this.handleSelectedTopics(data.topic);
    this.enableAndDisableTopic(data.id);
  }

  handleSelectedTopics(topic: string): void {
    this.split();
    this.getStorageProduct();
    this.createArraySelectedTopics(topic);
    this.removeSelectedTopic(topic);
  }

  split(): void {
    this.productName = this.router.url.split('/')[3];
  }

  getStorageProduct(): void {
    this.storageProduct = this.getQuestionsData(this.product).questions;
  }

  createArraySelectedTopics(topic: string): void {
    const selectedTopic = this.storageProduct.filter((product: Question) => {
      if (product.topic === topic) return product;
    });
    this.selectedChips = [...this.selectedChips, ...selectedTopic];
    this.visibleQuestions = this.selectedChips;
    this.lastQuestionVisible = [...this.visibleQuestions];
    this.length = this.lastQuestionVisible.length;
    this.handleItem(this.visibleQuestions, (this.index = 0));
  }

  removeSelectedTopic(topic: string): void {
    this.topics.filter((item) => {
      if (item.topic === topic && item.active) {
        this.removeTopic(topic);
      }
    });
  }

  removeTopic(topic: string): void {
    const alreadySelected = this.selectedChips.findIndex(
      (item) => item.topic === topic
    );
    if (alreadySelected >= 0) {
      const filteredTopics = this.selectedChips.filter(
        (item) => item.topic !== topic
      );
      this.selectedChips = [...filteredTopics];
      this.visibleQuestions = this.selectedChips;
      this.lastQuestionVisible = this.visibleQuestions;
    }
  }

  checkLengthVisibleQuestions(): void {
    if (this.visibleQuestions.length <= 0) {
      this.checksSessionStorage();
    }
  }

  enableAndDisableTopic(id: number): void {
    this.topics.filter((item) => {
      if (item.id === id && item.active === false) {
        item.active = true;
      } else if (item.id === id && item.active) {
        item.active = false;
      }
      this.checkLengthVisibleQuestions();
    });
  }

  createNewQuestions(index: number): void {
    const newQuestions = this.lastQuestionVisible.slice(
      index,
      this.offset + this.index
    );
    this.index += this.offset;
    this.visibleQuestions = [...this.visibleQuestions, ...newQuestions];
  }

  initializeSchema(): void {
    const schema = this.schemaProduct.map((item) => {
      let mainEntity =
      {
        '@type': 'Question',
        'name': item.question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': item.answer,
        },
      }
      mainEntity = { ...mainEntity };
      return mainEntity
    });
    this.jsonLD[0]['mainEntity'] = [...schema];
    this.schema = this.jsonLD;
  }
}
