import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShowFooterService {

  private disabled = new BehaviorSubject<boolean>(false);

  constructor() { }

  activeFooter(value: boolean): void {
    if (value)
      this.disabled.next(true);
    else
      this.disabled.next(false);
  }

  showFooter(): Observable<boolean> {
    return this.disabled.asObservable().pipe(distinctUntilChanged());
  }
}
