import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { SalesforceResponse } from '@models/salesforce-data';
import { AuthenticationService } from '@services/authentication.service';
import { SalesforceService } from '@services/salesforce.service';
import { ToastMessageService } from '@services/toast-message.service';
@Component({
  selector: 'app-modal-forgot-password',
  templateUrl: './modal-forgot-password.component.html',
  styleUrls: ['./modal-forgot-password.component.scss'],
})
export class ModalForgotPasswordComponent implements OnInit {
  form: UntypedFormGroup;
  loading: boolean = false;
  emailPattern =
    // tslint:disable-next-line: max-line-length
    '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';
  constructor(
    private modalController: ModalController,
    private formBuilder: UntypedFormBuilder,
    private salesforceService: SalesforceService,
    private authService: AuthenticationService,
    private toastMessage: ToastMessageService,
    private analytics: AngularFireAnalytics,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  // Convenience getter for easy access to form fields
  get field(): UntypedFormGroup['controls'] {
    return this.form.controls;
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.maxLength(256),
          Validators.email,
          Validators.pattern(this.emailPattern),
          Validators.required,
        ]),
      ],
    });
  }

  onSubmit(): void {
    this.analytics.logEvent('mailing_button_forgot_password').then(() =>
      this.handleForgotPassword(this.form.value.email)
    );
  }

  handleForgotPassword(email: string): void {
    this.loading = true;
    const mail = { email: email };
    this.salesforceService
      .find(mail)
      .subscribe((response: SalesforceResponse) => {
        if (!response.data.status) {
          this.loading = false;
          this.toastMessage.message(response.data.msgErro);
        } else {
          this.authService.forgotPassword(email).then(() => {
            this.loading = false;
            this.dismiss();
          });
        }
      });
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
