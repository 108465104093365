// Angular
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

// Ionic
import { IonInfiniteScroll } from '@ionic/angular';

// Models
import { Question } from '@models/question';

// Services
import { ShowFooterService } from '@services/show-footer.service';

// Variables
export const ionIcon = 'ION-ICON';
@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit, OnChanges {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  @Input() set length(index: number) {
    this.isLength = index;
  }
  @Input() set accordionData(data: Question[]) {
    this.questions = data;
  }
  @Output() orderMoreQuestions = new EventEmitter();

  questions: Question[] = [];
  currentIndex = undefined;
  currentElement: HTMLElement = undefined;
  isLength: number;


  constructor(private showFooter: ShowFooterService) { }

  ngOnInit(): void { }

  moreQuestions(): void {
    this.orderMoreQuestions.emit(this.questions.length);
  }

  ngOnChanges(): void {
    if (this.infiniteScroll) this.infiniteScroll.disabled = false;
  }

  loadData(event: any): void {
    setTimeout(() => {
      this.moreQuestions();
      event.complete();
    }, 500);
  }

  notFinished(): boolean {
    if (this.questions.length !== this.isLength) {
      this.activeFooter(false);
      return true;
    } else {
      this.activeFooter(true);
      return false;
    }
  }

  activeFooter(value: boolean): void {
    this.showFooter.activeFooter(value);
  }

  toggleAccordion(element: HTMLElement, index: number): void {
    // ionIcon Click
    if (element.nodeName === ionIcon) {
      element = element.parentElement.parentElement;
    }

    // First Time Click
    if (this.currentIndex === undefined) {
      this.activeToggle(element, index);
      this.nextPanel(element);
      this.saveState(element, index);
    } else {
      // Not Same Item
      if (this.currentIndex !== index) {
        // Current Item is still Open
        if (this.currentElement.classList.contains('active')) {
          this.activeToggle(this.currentElement, this.currentIndex);
          this.nextPanel(this.currentElement);
        }
        // Toogle New Item
        this.activeToggle(element, index);
        this.nextPanel(element);
        this.saveState(element, index);
      }
      // Same Item
      else {
        this.activeToggle(element, index);
        this.nextPanel(element);
      }
    }
  }

  saveState(element: HTMLElement, index: number): void {
    this.currentElement = element;
    this.currentIndex = index;
  }

  activeToggle(element: HTMLElement, index: number): void {
    element.classList.toggle('active');
    if (this.questions[index].isActive) {
      this.questions[index].isActive = false;
    } else {
      this.questions[index].isActive = true;
    }
  }

  nextPanel(element: any): void {
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }
}
