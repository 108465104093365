import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Mailing, SalesforceResponse, LoggedInUser } from '@models/salesforce-data';
import { SalesforceService } from '@services/salesforce.service';
import { ToastMessageService } from '@services/toast-message.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UnsubscribeRequest } from '@models/unsubscriber';
import { OPTIONS } from '@models/unsubscribe-options';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-modal-unsubscribe',
  templateUrl: './modal-unsubscribe.component.html',
  styleUrls: ['./modal-unsubscribe.component.scss'],
})
export class ModalUnsubscribeComponent implements OnInit {
  @Input() set data(data: Mailing) {
    this.cleanObject(data);
  }

  @Input() set props(data: { id: number | string, action: string }) {
    this._props = data;
  }

  form: UntypedFormGroup;
  mailing: any;
  loading: boolean = false;
  user: LoggedInUser;
  textarea: boolean = false;
  reasonChosen: string;
  submitted: boolean = false;
  options = OPTIONS;
  _props!: { id: number | string, action: string };

  constructor(
    private modalController: ModalController,
    public alertController: AlertController,
    private toastMessageService: ToastMessageService,
    private salesforceService: SalesforceService,
    private formBuilder: UntypedFormBuilder,
    private analytics: AngularFireAnalytics,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      message: [''],
    });
  }

  cleanObject(data: Mailing): void {
    data.gruposMailings.map((item: any) => {
      item.inscritoGrupo = false;
      item.mailings.map((fund: any) => {
        fund.inscritoMailing = false;
      });
    });

    this.mailing = {
      inscritoTodos: data.inscritoTodos = false,
      gruposMailings: data.gruposMailings
    };
  }

  onSubmit(): void {
    this.loading = true;

    if (this._props.action === 'unsubscribe')
      this.unsubscribeMailing();
    else
      this.handlerUpdateSalesforce();
  }

  unsubscribeMailing(): void {

    const data = {
      id: this._props.id,
      reason: this.reasonChosen,
      message: this.form.value.message,
    }

    this.salesforceService.unsubscribe(data)
      .subscribe((response: any) => {
        this.loading = false;

        if (!response.update.status) {
          this.toastMessageService.message(response.update.message);
          this.submitted = false;
        }
        else {
          this.analytics.logEvent('email_unsubscribe')
            .then(() =>
              this.toastMessageService.message(response.update.message)
                .then(() => this.dismiss())
            );
        }
      })
  }

  handlerUpdateSalesforce(): void {
    this.getUser();
    const data: UnsubscribeRequest = {
      motivoCancelamento: this.reasonChosen,
      textoOutros: this.form.value.message,
      todosMailings: { ...this.mailing }
    };

    this.updateSalesforce(data);
  }

  updateSalesforce(data: UnsubscribeRequest): void {
    this.salesforceService.update(data)
      .subscribe((response: SalesforceResponse) => {
        this.user.groupMailing = response.data.todosMailings.gruposMailings;
        this.user.all = response.data.todosMailings.inscritoTodos;
        this.setUser(this.user);
        this.loading = false;
        this.analytics.logEvent('subscription_canceled')
          .then(() =>
            this.toastMessageService.message('Subscrição cancelada!')
              .then(() => this.dismiss())
          );
      });
  }



  select(event: { target: HTMLInputElement }): void {
    this.reasonChosen = event.target.value;
    this.submitted = true;
    event.target.value === 'Outros'
      ? this.textarea = true
      : this.textarea = false;
  }

  getUser(): void {
    this.user = JSON.parse(sessionStorage.getItem('user'));
  }

  setUser(user: LoggedInUser): void {
    sessionStorage.setItem('user', JSON.stringify(user));
  }

  dismiss(): void {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
