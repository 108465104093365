import { RefreshUserService } from './../../../services/refresh-user.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ErrorResponse } from '@models/error';
import { SalesforceResponse } from '@models/salesforce-data';
import { CreateUser, CreateUserFormControl } from '@models/create-user';
import { AuthenticationService } from '@services/authentication.service';
import { ErrorService } from '@services/error.service';
import { SalesforceService } from '@services/salesforce.service';
import { ToastMessageService } from '@services/toast-message.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-modal-edit',
  templateUrl: './modal-edit.component.html',
  styleUrls: ['./modal-edit.component.scss'],
})
export class ModalEditComponent implements OnInit {

  form: FormGroup<CreateUserFormControl>;
  icon = 'eye-off-outline'
  iconConfirm = 'eye-off-outline';
  valid: boolean;
  isValid = true;
  confirmPass = true;
  isValidCpf = false;
  isValidPass = false;
  isChannel = true;
  message: string;
  error: boolean = false;
  isEmailError: boolean = false;
  isCpfError: boolean = false;
  isPhoneError: boolean = false;
  loading: boolean = false;
  submitted = false;
  user: any;
  namePattern = '[a-zA-ZÀ-ú ]*';
  emailPattern =
    // tslint:disable-next-line: max-line-length
    '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';
  storage: any;
  constructor(
    public modalController: ModalController,
    private authenticationService: AuthenticationService,
    private salesforceService: SalesforceService,
    private toastMessageService: ToastMessageService,
    private errorService: ErrorService,
    private refreshUserService: RefreshUserService,
    private analytics: AngularFireAnalytics,
  ) { }

  // Convenience getter for easy access to form fields
  get field(): FormGroup<CreateUserFormControl>['controls'] {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.getUserData();
  }

  getUserData(): void {
    this.loading = true
    this.storage = JSON.parse(sessionStorage.getItem('user'));
    const mail = { email: this.storage.email }
    this.salesforceService.find(mail)
      .subscribe((response: any) => {
        if (response.data.status) {
          this.userEdit(response.data)
            .then(() => { this.initForm(); this.loading = false });
        } else {
          this.error = true;
          this.message = response.data.msgErro
        }
      });
  }

  initForm(): void {

    this.form = new FormGroup<CreateUserFormControl>({
      firstName: new FormControl<string | null>(this.user.firstName, Validators.compose([
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern(this.namePattern)
      ])),
      lastName: new FormControl<string | null>(this.user.lastName, Validators.compose([
        Validators.required,
        Validators.maxLength(64),
        Validators.pattern(this.namePattern)
      ])),
      cpf: new FormControl<string | null>({ value: this.user.cpf, disabled: true }),
      email: new FormControl<string | null>(this.user.email, Validators.compose([
        Validators.required,
        Validators.maxLength(256),
        Validators.pattern(this.emailPattern),
      ])),
      phone: new FormControl<string | null>(this.user.phone, Validators.compose([
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14)
      ])),
      password: new FormControl<string | null>({ value: '**************', disabled: true }),
      confirm: new FormControl<string | null>({ value: '**************', disabled: true }),
      privacy: new FormControl<boolean>({ value: this.user.privacy, disabled: true }),
    })
  }


  onSubmit(): void {
    const user = this.createUser();
    this.loading = true;
    const isDistinct = user.email !== this.user.email ? true : false;
    this.optionCheck(isDistinct);
  }

  optionCheck(param: boolean): void {
    const user = this.createUser();
    param ? this.updateEmailAndUser(user) : this.updateUser(user)
  }

  // checkChannel(): void {
  //   this.form.controls.mail.value || this.form.controls.whatsapp.value
  //     ? (this.isChannel = true)
  //     : (this.isChannel = false);
  // }

  async userEdit(data: any): Promise<void> {
    // const isChannel = data.canaisPersona ? data.canaisPersona : '';
    // const channel = isChannel.split(';');

    this.user = {
      firstName: data.primeiroNomePersona,
      lastName: data.ultimoNomePersona,
      cpf: data.cpfPersona,
      email: data.emailPersona,
      phone: data.telefonePersona,
      // mail: channel[0] ? true : false,
      // whatsapp: channel[1] ? true : false,
      privacy: data.statusPersona
    }

  }

  updateUser(user: CreateUser): void {
    this.salesforceService.create(user)
      .subscribe((response: SalesforceResponse) => {

        if (response.data.status) {
          this.updateStorage(user);
          this.loading = false;
          this.analytics.logEvent('mailing_update_user').then(() => {
            this.toastMessageService.message('Usuario atualizado com sucesso!');
            this.dismiss();
          });
        } else {
          this.errorValidation(response.data.codErro)
          this.message = response.data.msgErro;
          this.loading = false;
        }

      });
  }

  updateEmailAndUser(user: CreateUser): void {
    if (!this.refreshUserService.user) {
      this.updateUserErrorMessage();
    }
    else {
      this.salesforceService.create(user)
        .subscribe((response: SalesforceResponse) => {

          if (response.data.status) {
            this.authenticationService.updateEmail(this.storage.uid, user.email)
              .then(() => {
                this.updateStorage(user);
                this.loading = false;
                this.analytics.logEvent('mailing_update_user').then(() => {
                  this.toastMessageService.message('Usuario atualizado com sucesso!');
                  this.dismiss();
                });
              }).catch((error) => {
                this.errorService
                  .error(error.code)
                  .then((errorResponse: ErrorResponse) => {
                    this.loading = false;
                    this.error = true;
                    this.message = errorResponse.message;
                  });
              })
          }
          else {
            this.errorValidation(response.data.codErro)
            this.message = response.data.msgErro;
            this.loading = false;
          }

        });
    }
  }

  updateStorage(data: any): void {
    const user = JSON.parse(sessionStorage.getItem('user'));
    user.email = data.email;
    user.displayName = data.primeiroNome;
    user.name = data.nome;
    sessionStorage.setItem('user', JSON.stringify(user));
  }

  createUser(): CreateUser {
    const user = {
      primeiroNome: this.form.value.firstName.replace(/^\w/, (name: string) => name.toUpperCase()),
      ultimoNome: this.form.value.lastName.replace(/\w\S*/g, (lastName: string) => (lastName.replace(/^\w/, (name: string) => name.toUpperCase()))),
      cpf: this.user.cpf,
      email: this.form.value.email,
      telefone: this.form.value.phone.replace(/[^\d]+/g, ''),
      canais: 'Email',
      consentimento: this.user.privacy,
      tipo: 'update'
    };
    return user;
  }

  dismiss(): void {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  updateUserErrorMessage(): void {
    this.toastMessageService.message('Não foi possível Editar seu usuário, tente novamente mais tarde!')
      .then(() => { this.loading = false; this.dismiss() })
  }

  errorValidation(code: string): void {
    switch (code) {
      case 'cpf':
        this.isCpfError = true;
        this.isPhoneError = false;
        this.isEmailError = false;
        break;
      case 'telefone':
        this.isPhoneError = true;
        this.isCpfError = false;
        this.isEmailError = false;
        break;
      case 'email':
        this.isEmailError = true;
        this.isCpfError = false;
        this.isPhoneError = false;
        break;
    }
  }
}
