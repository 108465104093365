import { ErrorService } from './error.service';
import { RefreshUserService } from '@services/refresh-user.service';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ToastMessageService } from './toast-message.service';
import firebase from "firebase/compat/app";
import "firebase/auth";
import { ErrorResponse } from '@models/error';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private fbAuth: AngularFireAuth,
    private toastMessageService: ToastMessageService,
    private refreshService: RefreshUserService,
    private errorService: ErrorService,
  ) { }

  async signUp(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return await this.fbAuth.createUserWithEmailAndPassword(email, password)
  }

  async signIn(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return await this.fbAuth.signInWithEmailAndPassword(email, password)

  }

  async updatePassword(newPassword: string, uid: string): Promise<void> {
    await this.refreshService.reauthenticateWithCredential(uid)
      .then(() => {
        setTimeout(() => {
          this.fbAuth.user.subscribe((response: firebase.User) => response.updatePassword(newPassword))
        }, 2000);
      })
  }

  async updateEmail(uid: string, email: string): Promise<void> {
    await this.refreshService.reauthenticateWithCredential(uid)
      .then(() => {
        setTimeout(() => {
          this.fbAuth.user.subscribe((response: firebase.User) => response.updateEmail(email));
        }, 2000);
      });
  }

  async delete(uid: string): Promise<void> {
    await this.refreshService.reauthenticateWithCredential(uid)
      .then(() => {
        setTimeout(() => {
          this.fbAuth.user.subscribe((response: firebase.User) => response.delete());
        }, 2000);
      });
  }

  async forgotPassword(passwordResetEmail: any): Promise<any> {
    const actionCodeSettings = {
      url: `${environment.redirect_url}?type=login`,
      handleCodeInApp: false
    };
    console.log(actionCodeSettings)
    return this.fbAuth.sendPasswordResetEmail(passwordResetEmail, actionCodeSettings)
      .then(() => {
        this.toastMessageService.messageConfirm('Link de redefinição de senha enviado, verifique sua caixa de entrada.')
      }).catch((error) => {
        this.errorService.error(error.code)
          .then((response: ErrorResponse) => {
            this.toastMessageService.messageConfirm(response.message)
          })
      })
  }

  async emailVerification(uid: any): Promise<void> {
    const actionCodeSettings = {
      url: `${environment.redirect_url}?type=login&code=${uid}`,
      handleCodeInApp: false
    };

    firebase.auth().currentUser.sendEmailVerification(actionCodeSettings)
      .then(() => {
        this.toastMessageService.messageConfirm('Link de confirmação de e-mail enviado, verifique sua caixa de entrada.')
      }).catch((error) => {
        this.errorService.error(error.code)
          .then((response: ErrorResponse) => {
            this.toastMessageService.messageConfirm(response.message)
          })
      })
  }

}
