import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

//Models
import { Topic } from '@models/topic';
@Component({
  selector: 'chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
})
export class ChipsComponent implements OnInit {
  @Input() set topic(data: Topic[]) {
    this.topics = data;
  }
  @Output() selectedChips = new EventEmitter();

  topics: Topic[];

  constructor() { }

  ngOnInit() { }

  selectChip(id: number, topic: string) {
    this.selectedChips.emit({ id, topic });
  }
}
