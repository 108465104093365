import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Error, ErrorResponse } from '@models/error';
import { SalesforceResponse } from '@models/salesforce-data';
import { CreateUser, CreateUserFormControl } from '@models/create-user';
import { AuthenticationService } from '@services/authentication.service';
import { ErrorService } from '@services/error.service';
import { SalesforceService } from '@services/salesforce.service';
import { cpf } from 'cpf-cnpj-validator';
// import custom validator to validate that password and confirm password fields match
import { MustMatch } from '../../../helpers/must-match.validator';
import { ScreenSizeService } from '@services/screen-size.service';
import { Observable } from 'rxjs';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-modal-register',
  templateUrl: './modal-register.component.html',
  styleUrls: ['./modal-register.component.scss'],
})
export class ModalRegisterComponent implements OnInit {
  isDesktop: boolean;
  isDesktop$: Observable<boolean> = this.screenSizeService.isDesktopView();
  form!: FormGroup<CreateUserFormControl>;
  type = 'password';
  icon = 'eye-off-outline';
  typeConfirm = 'password';
  iconConfirm = 'eye-off-outline';
  isValid = true;
  confirmPass = true;
  isValidCpf = false;
  // isChannel = true;
  message: string;
  error: boolean = false;
  isEmailError: boolean = false;
  isCpfError: boolean = false;
  isPhoneError: boolean = false;

  loading: boolean = false;
  submitted = false;
  namePattern = '[a-zA-ZÀ-ú ]*';
  emailPattern =
    // tslint:disable-next-line: max-line-length
    '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';

  constructor(
    private screenSizeService: ScreenSizeService,
    public modalController: ModalController,
    private authenticationService: AuthenticationService,
    private salesforceService: SalesforceService,
    private errorService: ErrorService,
    private analytics: AngularFireAnalytics,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.isDesktopSubscriber();
  }

  isDesktopSubscriber(): void {
    this.isDesktop$.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });
  }

  initForm(): void {

    this.form = new FormGroup<CreateUserFormControl>({
      firstName: new FormControl<string | null>('', Validators.compose([
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern(this.namePattern),
      ])),
      lastName: new FormControl<string | null>('', Validators.compose([
        Validators.required,
        Validators.maxLength(64),
        Validators.pattern(this.namePattern),
      ])),
      cpf: new FormControl<string | null>('', Validators.compose([
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14),
      ])),
      email: new FormControl<string | null>('', Validators.compose([
        Validators.maxLength(256),
        Validators.email,
        Validators.pattern(this.emailPattern),
        Validators.required,
      ])),
      phone: new FormControl<string | null>('', Validators.compose([
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14),
      ])),
      password: new FormControl<string | null>('', Validators.compose([Validators.required, Validators.minLength(6)]),),
      confirm: new FormControl<string | null>('', Validators.compose([Validators.required, Validators.minLength(6)]),),
      privacy: new FormControl<boolean>(false, Validators.requiredTrue),
    },
      {
        validators: MustMatch('password', 'confirm'),
      }
    )
  }

  // Convenience getter for easy access to form fields
  get field(): FormGroup<CreateUserFormControl>['controls'] {
    return this.form.controls;
  }

  dismiss(): void {
    this.loading = false;
    this.dismissModal();
  }

  dismissModal(): void {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.loading = true;
    const user = this.createUser();
    this.salesforceService
      .create(user)
      .subscribe((apiResponse: SalesforceResponse) => {
        if (apiResponse.data.status) {
          this.authenticationService
            .signUp(this.form.value.email, this.form.value.password)
            .then(() => {
              this.sendAnalytics('create_user');
              this.authenticationService
                .emailVerification(apiResponse.data.idPersona)
                .then(() => {
                  this.dismiss();
                });
            })
            .catch((error: Error) => {
              this.errorService
                .error(error.code)
                .then((errorResponse: ErrorResponse) => {
                  this.loading = false;
                  this.error = true;
                  this.message = errorResponse.message;
                });
            });
        } else {
          this.errorValidation(apiResponse.data.codErro);
          this.message = apiResponse.data.msgErro;
          this.loading = false;
        }
      });
  }

  validateCpf(event: { target: HTMLInputElement }): void {
    const isCPF = cpf.isValid(event.target.value);
    this.isValid = isCPF;
    this.isValidCpf = isCPF;
  }

  async storeUserData(
    response: SalesforceResponse,
    uid: string
  ): Promise<void> {
    const user = {
      id: response.data.idPersona,
      displayName: response.data.primeiroNomePersona,
      name: response.data.nomePersona,
      email: response.data.emailPersona,
      authorization: response.data.status,
      groupMailing: response.data.todosMailings.gruposMailings,
      all: response.data.todosMailings.inscritoTodos,
      uid: uid,
    };
    sessionStorage.setItem('user', JSON.stringify(user));
  }

  createUser(): CreateUser {
    const user = {
      primeiroNome: this.textHandler(this.form.value.firstName),
      ultimoNome: this.textHandler(this.form.value.lastName),
      cpf: this.removeMask(this.form.value.cpf),
      email: this.form.value.email,
      telefone: this.removeMask(this.form.value.phone),
      canais: 'Email',
      consentimento: this.form.value.privacy,
      tipo: 'create',
      emailVerificado: false,
    };
    return user;
  }

  sendAnalytics(idName: string): void {
    this.analytics.logEvent(idName);
  }

  openLock(): void {
    switch (this.type) {
      case 'password':
        this.type = 'text';
        this.icon = 'eye-outline';
        break;
      case 'text':
        this.type = 'password';
        this.icon = 'eye-off-outline';
        break;
    }
  }

  openLockConfirm(): void {
    switch (this.typeConfirm) {
      case 'password':
        this.typeConfirm = 'text';
        this.iconConfirm = 'eye-outline';
        break;
      case 'text':
        this.typeConfirm = 'password';
        this.iconConfirm = 'eye-off-outline';
        break;
    }
  }

  errorValidation(code: string): void {
    switch (code) {
      case 'cpf':
        this.isCpfError = true;
        this.isPhoneError = false;
        this.isEmailError = false;
        break;
      case 'telefone':
        this.isPhoneError = true;
        this.isCpfError = false;
        this.isEmailError = false;
        break;
      case 'email':
        this.isEmailError = true;
        this.isCpfError = false;
        this.isPhoneError = false;
        break;
    }
  }

  textHandler(text: string): string | undefined {
    const textNormalize: string = text.trim().toLocaleLowerCase('pt-BR');
    const words = textNormalize.split(' ');

    const treatedWords = words.map((word) => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1);
      return firstLetter + restOfWord;
    });

    return treatedWords.join(' ');
  }

  removeMask(text: string): string {
    const textNormalize = text.replace(/[^\d]+/g, '');
    return textNormalize;
  }
}
