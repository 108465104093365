interface Option {
    name: string;
}

export const OPTIONS: Option[] = [
    { name: 'Nunca me cadastrei neste mailing' },
    { name: 'Não quero mais receber este conteúdo' },
    { name: 'O envio é excessivo' },
    { name: 'O conteúdo não me interessou' },
    { name: 'Outros' },
];