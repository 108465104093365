import { Injectable } from '@angular/core';

// Firebase
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private db: AngularFirestore) { }

  getQuestionsAboutProduct(product: string): Observable<DocumentData> {
    return this.db
      .collection('faq')
      .doc('products')
      .collection(`${product}`)
      .doc('questions')
      .get();
  }

  getQuestionsAsset() {
    return this.db
      .collection('faq')
      .doc('asset')
      .get();
  }

}
