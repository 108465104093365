import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SalesforceResponse } from '@models/salesforce-data';
import { Email } from '@models/create-user';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SalesforceService {
  // Verificar o Environment.
  readonly base_url = environment.url;

  constructor(private http: HttpClient) { }

  public getMailing(): Observable<SalesforceResponse> {
    return this.http.get<SalesforceResponse>(`${this.base_url}/mailings`).pipe(
      retry(2),
      catchError((error) => this.handleError(error))
    );
  }

  public find(params: Email): Observable<SalesforceResponse> {
    return this.http
      .post<SalesforceResponse>(`${this.base_url}/mailing/email`, params)
      .pipe(
        retry(2),
        catchError((error) => this.handleError(error))
      );
  }

  public create(params: any): Observable<SalesforceResponse> {
    return this.http
      .post<SalesforceResponse>(`${this.base_url}/mailing/create`, params)
      .pipe(
        retry(2),
        catchError((error) => this.handleError(error))
      );
  }

  public update(params: any): Observable<SalesforceResponse> {
    return this.http
      .put<SalesforceResponse>(`${this.base_url}/mailing/update`, params)
      .pipe(
        retry(2),
        catchError((error) => this.handleError(error))
      );
  }

  public unsubscribe(params: any): Observable<SalesforceResponse> {
    return this.http
      .post<SalesforceResponse>(`${this.base_url}/mailing/unsubscribe`, params)
      .pipe(
        retry(2),
        catchError((error) => this.handleError(error))
      );
  }

  public delete(id: string): Observable<SalesforceResponse> {
    const userId = { idPersona: id };
    return this.http
      .post<SalesforceResponse>(`${this.base_url}/mailing/delete`, userId)
      .pipe(
        retry(2),
        catchError((error) => this.handleError(error))
      );
  }

  handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = this.getServerErrorMessage(error);
    }
    return throwError(errorMessage);
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 400: {
        return `Requisição inválida: ${error.message}`;
      }
      case 403: {
        return `Accesso Proibido: ${error.message}`;
      }
      case 404: {
        return `O recurso requisitado não foi encontrado: ${error.message}`;
      }
      case 408: {
        return `Tempo de requisição esgotou: ${error.message}`;
      }
      case 500: {
        return `Erro interno do servidor: ${error.message}`;
      }
      case 501: {
        return `Não implementado: ${error.message}`;
      }
      case 502: {
        return `Bad Gateway: ${error.message}`;
      }
      case 503: {
        return `Serviço indisponível: ${error.message}`;
      }
      case 504: {
        return `Gateway Time-Out: ${error.message}`;
      }
      default: {
        return `Error desconhecido do servidor: ${error.message}`;
      }
    }
  }
}
