import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SalesforceResponse } from '@models/salesforce-data';
import { AuthenticationService } from '@services/authentication.service';
import { SalesforceService } from '@services/salesforce.service';
import { ToastMessageService } from '@services/toast-message.service';
import { RefreshUserService } from '@services/refresh-user.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.scss'],
})
export class ModalDeleteComponent implements OnInit {
  user: Storage;
  loading: boolean = false;
  isAuthenticated: boolean = false;

  constructor(
    public modalController: ModalController,
    private salesforceService: SalesforceService,
    private toastMessageService: ToastMessageService,
    private authService: AuthenticationService,
    private refreshUserService: RefreshUserService,
    private analytics: AngularFireAnalytics,
  ) { }

  ngOnInit(): void {
    this.checkingUser();
  }

  checkingUser(): void {
    this.user = JSON.parse(sessionStorage.getItem('user'));
  }

  onSubmit(): void {
    this.loading = true;

    const navigateToHome = () =>
      setTimeout(() => {
        window.location.assign('/home');
      }, 3000);

    if (!this.refreshUserService.user) {
      this.removeUserErrorMessage();
    } else {
      this.loading = true;
      this.salesforceService
        .delete(this.user.id)
        .subscribe((response: SalesforceResponse) => {
          if (!response.data.status) {
            this.loading = false;
            this.toastMessageService
              .message(response.data.msgErro)
              .then(() => this.dismissModal());
          } else {
            this.authService
              .delete(this.user.uid)
              .then(() => this.sendAnalytics('mailing_deleted_user'))
              .then(navigateToHome)
              .catch((error) => console.log(error));
          }
        });
    }
  }

  sendAnalytics(idName: string): void {
    this.analytics.logEvent(idName)
      .then(() => this.handleRemoveUser());
  }

  handleRemoveUser(): void {
    this.removeUser();
    this.dismiss();
    this.removeUserSuccessMessage();
  }

  removeUser(): void {
    sessionStorage.removeItem('user');
  }

  removeUserSuccessMessage(): void {
    this.toastMessageService.message('Todos os seus dados foram excluidos');
  }

  removeUserErrorMessage(): void {
    this.toastMessageService
      .message('Não foi possível excluir seu usuário, tente novamente mais tarde!')
      .then(() => this.dismiss());
  }

  dismiss(): void {
    this.loading = false;
    this.dismissModal();
  }

  dismissModal(): void {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
