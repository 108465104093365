// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCsnwAPUa9aOgVsRVCUepar6xlZU6BQOuk",
    authDomain: "ri-riza-asset.firebaseapp.com",
    projectId: "ri-riza-asset",
    storageBucket: "ri-riza-asset.appspot.com",
    messagingSenderId: "12852918580",
    appId: "1:12852918580:web:54796062fbacc9836b8d61",
    measurementId: "G-334RBMF2DE",

  },
  version: require('../../package.json').version,
  url: 'https://us-central1-riza-asset.cloudfunctions.net/sitesalesforce/api',
  redirect_url: 'https://ri.rizaasset.com/home/mailing'
  // url: 'http://localhost:3000/api',
  // url: 'http://127.0.0.1:5001/riza-asset/us-central1/sitesalesforce/api',
  // redirect_url: 'https://ri-riza-asset-dev.web.app/home/mailing'
  // redirect_url: 'https://ri-riza-asset-stage.web.app/home/mailing'
  // redirect_url: 'https://ri-riza-asset-dev--mailing-3wa90gzi.web.app/home/mailing'
  // redirect_url: 'http://localhost:8100/home/mailing'

}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
