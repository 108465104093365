import { Platform } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JsonLD } from '@models/jsonLD';
import { ProductService } from '@services/product.service';
import { ShowFooterService } from '@services/show-footer.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss'],
})
export class ManagerComponent implements OnInit {
  questions: any;
  length: number;
  schemaProduct: any;
  jsonLD = JsonLD;
  schema: any;
  isMobile: boolean;

  constructor(
    private showFooter: ShowFooterService,
    private productService: ProductService,
    private routes: Router,
    private platform: Platform
  ) { }

  ngOnInit() {
    this.showFooter.activeFooter(true);
    this.getQuestions();
    this.handleUrl(this.routes.url);
    this.getPlatform();

  }

  getQuestions() {
    this.productService.getQuestionsAsset().subscribe((querySnapshot) => {
      this.questions = JSON.parse(querySnapshot.data()['data']);
      this.schemaProduct = this.questions;
      this.length = this.questions.length;
      this.initializeSchema();
    });
  }

  initializeSchema(): void {
    const schema = this.schemaProduct.map((item) => {
      let mainEntity =
      {
        '@type': 'Question',
        'name': item.question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': item.answer,
        },
      }
      mainEntity = { ...mainEntity };
      return mainEntity
    });
    this.jsonLD[0]['mainEntity'] = [...schema];
    this.schema = this.jsonLD;
  }

  handleUrl(url: string): void {
    const slugArray = url.split('/');
    if (slugArray.length > 3 && slugArray[4] === 'income-report') {
      this.openAccordion();
      return;
    }
  }

  openAccordion(): void {
    timer(1500).subscribe(() => this.getElementAndAddStyle());
  }

  getElementAndAddStyle(): void {
    const element = document.getElementById('0');
    const divAccordion = element.querySelectorAll('div')[0];
    const divPanel = element.querySelectorAll('div')[1];
    divAccordion.classList.add("active");
    if (this.isMobile) {
      divPanel.style.maxHeight = '921px';
      return;
    }

    divPanel.style.maxHeight = '461px';
  }

  getPlatform(): void {
    const platform = this.platform.platforms().filter(platform => platform === 'mobile')[0];
    this.isMobile = !platform ? false : true;
  }


}
