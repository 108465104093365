import { Injectable } from '@angular/core';
import { ErrorResponse } from '@models/error';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private message: string;
  private isError: boolean;
  constructor() {}

  public async error(code: string): Promise<ErrorResponse> {
    switch (code) {
      case 'auth/email-already-in-use':
        this.message =
          'O endereço de e-mail já está sendo usado por outra conta.';
        this.isError = true;
        break;
      case 'auth/user-not-found':
        this.message = 'Usuário não encontrado.';
        this.isError = true;
        break;
      case 'auth/wrong-password':
        this.message = 'A senha é inválida ou o usuário não possui uma senha.';
        this.isError = true;
        break;
      case 'auth/email-already-in-use':
        this.message =
          'O endereço de e-mail já está sendo usado por outra conta.';
        this.isError = true;
        break;
      case 'auth/requires-recent-login':
        this.message =
          'Esta operação é sensível e requer avaliação recente. Faça login novamente antes de tentar novamente esta solicitação.';
        this.isError = true;
        break;
      case 'cpf':
        this.isError = true;
        this.message = '';
        break;
      case 'telefone':
        this.isError = true;
        this.message = '';
      case 'email':
        this.isError = true;
        this.message = '';
    }
    return { message: this.message, error: this.isError };
  }
}
