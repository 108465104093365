import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { CheckForUpdateService } from '@services/check-for-update.service';
import { ScreenSizeService } from '@services/screen-size.service';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('chat') chat!: ElementRef;

  onDialogFlowLoadSubscription: any;
  isDesktop$: Observable<boolean> = this.screenSizeService.isDesktopView();
  subscription: any;
  isDesktop: boolean;
  isIframe: boolean = false;

  constructor(
    private platform: Platform,
    private screenSizeService: ScreenSizeService,
    private window: Window,
    private analytics: AngularFireAnalytics,
    private checkForUpdate: CheckForUpdateService,
    private router: Router
  ) { }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.screenSizeService.onResize(event.target.innerWidth);
  }

  ngOnInit(): void {
    this.initializeApp();
    if (environment.production) {
      this.checkForUpdate.checkForUpdates();
    }
    this.isDesktopSubscriber();
    this.isLoadedViaIframe();
    this.sendPostMessage();
    this.receivePostMessage();
  }

  sendPostMessage(): void {
    // Send a message to the parent
    window.parent.postMessage('loaded', 'https://www.rizaasset.com');
    // window.parent.postMessage('loaded', 'http://localhost:8100');

  }

  receivePostMessage(): void {
    window.addEventListener('message', (event) => {
      this.navigate(event.data);
    });
  }

  navigate(url: string) {
    if (typeof url === 'string') {
      this.router.navigate([url]);
    }
  }

  sendAnalytics(idName: string): void {
    this.analytics.logEvent(idName);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.screenSizeService.onResize(this.platform.width());
    });
  }

  isDesktopSubscriber(): void {
    this.subscription = this.isDesktop$.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });
  }

  isLoadedViaIframe(): void {
    if (this.window.self !== this.window.top) {
      this.isIframe = true;
    } else {
      this.isIframe = false;
    }
  }

}
