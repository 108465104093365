import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {

  constructor(private toastController: ToastController) { }

  async message(message: string): Promise<void> {
    const toast = await this.toastController.create({
      message: message,
      cssClass: 'custom-toast',
      mode: 'md',
      duration: 4000
    });
    toast.present();
  }

  async messageConfirm(message: string): Promise<void> {
    const toast = await this.toastController.create({
      message: message,
      cssClass: 'custom-toast',
      mode: 'md',
      buttons: [
        {
          side: 'end',
          text: 'OK',
          handler: () => { }
        }
      ],
    });
    toast.present();
  }
}
