import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment'
@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  version = environment.version
  constructor() { }

  ngOnInit() { }

}
